import stateData from "./state";
import service from "../../api";

let FLAG = "CREDIT";

export default {
  namespaced: true,
  state: {
    ...stateData,
  },
  mutations: {
    setData(state, data = {}) {
      for (let key in data) {
        state[key] = data[key] || "";
      }
    },
    // 设置查询版本
    setProvider(state, provider = "") {
      state.provider = provider || "";
    },
    //刷新时间
    setRefreshDate(state, date) {
      state.date = date;
    },
  },
  actions: {
    getData({ rootState, commit }, refresh) {
      let { uid = "" } = rootState.userReport;
      commit("userReport/setLoading", true, {
        root: true,
      });
      // 重置state
      commit("setData", {
        ...stateData,
      });
      return service
        .commonRisk(
          {
            uid,
            refresh,
          },
          FLAG
        )
        .then((res) => {
          let { provider = "", data = {}, date = "" } = res || {};
          commit("setData", {
            provider,
            data,
            date,
          });
          commit("userReport/setLoading", false, {
            root: true,
          });
          return true;
        })
        .catch(() => {
          commit("userReport/setLoading", false, {
            root: true,
          });
          return false;
        });
    },
  },
  getters: {
    data: (state) => {
      let res = state.data[FLAG]
        ? state.data[FLAG].data
          ? state.data[FLAG].data
          : {}
        : {};
      if (res.details && res.details.length > 0) {
        res.details.map((it, index) => {
          it.index = index + 1
          if (it.settlement && it.settlement == "Y") {
            it.settlement = "已结清"
          } else if (it.settlement && it.settlement == "N") {
            it.settlement = "未结清"
          }
        })
      }
      return res;
    },
  },
};
